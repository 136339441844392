


















































@import "@/assets/css/project-variables";
.human-resources-matrix-card-assessment-not-started {
  color: #4b4b4b !important;
}
.human-resources-matrix-card-assessment-started {
  color: #ff9100 !important;
}
.human-resources-matrix-card-assessment-finished {
  color: #20be00 !important;
}
